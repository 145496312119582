import Block from 'library/components/Block'
import OnetraceInfo from './components/OnetraceInfo'
import PageLinks from './components/PageLinks'
import React from 'react'
import { SchemeType } from 'theme'
import SocialLinks from './components/SocialLinks'
import styled from 'styled-components'

type Props = { scheme: SchemeType }

const Footer = ({ scheme }: Props) => (
  <Block scheme={scheme}>
    <Container>
      <Content>
        <LinkSection>
          <PageLinks />

          <SocialLinks />
        </LinkSection>

        <Bottom>
          <OnetraceInfo />
        </Bottom>
      </Content>
    </Container>
  </Block>
)

export default Footer

const Container = styled.div`
  width: 100%;
  padding: 64px 0;
  background: ${({ theme }) => theme.colors.charcoal5};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 80px 0;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 120px 0;
  }
`

const Content = styled.div`
  width: 343px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  row-gap: 48px;
  column-gap: 16px;
  margin: 0 auto;
  transition: ${({ theme }) => theme.transitions.width};

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 680px;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 960px;
    row-gap: 64px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 1200px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 1400px;
  }
`

const LinkSection = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 32px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }
`

const Bottom = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 7;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: span 6;
  }
`
