import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import Flex from 'library/components/Flex'
import Input from 'library/components/Input'
import { LegalPage } from 'library/content/legal-pages'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Text from 'library/components/Text'
import styled from 'styled-components'

type Props = {
  legalPage: LegalPage
  legalPages: LegalPage[]
}

const LegalBlock = ({ legalPage, legalPages }: Props) => {
  const headings: string[] = legalPage.content
    .split('\n')
    .filter((line) => line.startsWith('#### '))
    .map((line) => line.substring(5))

  const pageOptions = legalPages.map((legalPage) => ({
    label: legalPage.title,
    value: legalPage.slug,
  }))

  const goToPage = React.useCallback((slug: string) => {
    window.location.href = `/legal/${slug}`
  }, [])

  const scrollToHeading = React.useCallback((heading: string) => {
    document.querySelectorAll(`.content h4`).forEach((section) => {
      if (section.innerHTML == heading) {
        section.scrollIntoView()
        window.scrollBy(0, -100)
      }
    })
  }, [])

  return (
    <Block scheme='light'>
      <Centraliser>
        <Grid rowGap={24}>
          <Header>
            <Text tag='h1'>Legal information</Text>
          </Header>

          <Content>
            <Directories>
              {legalPages.map((legalPage) => (
                <Tab key={legalPage.title}>
                  <Button onClick={() => goToPage(legalPage.slug)} size='small'>
                    {legalPage.title}
                  </Button>

                  {legalPage.slug == legalPage.slug && (
                    <Sections>
                      {headings.map((heading) => (
                        <Section key={heading} onClick={() => scrollToHeading(heading)}>
                          {heading}
                        </Section>
                      ))}
                    </Sections>
                  )}
                </Tab>
              ))}
            </Directories>

            <Contents>
              <MobileDirectory>
                <Input.Select
                  name='privacy-policy-input'
                  options={pageOptions}
                  value={legalPage.slug}
                  onChange={(e) => goToPage(e.target.value)}
                />
              </MobileDirectory>

              <Flex column className='content'>
                <Text type='body-small-400'>
                  <Markdown>{legalPage.content}</Markdown>
                </Text>
              </Flex>
            </Contents>
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default LegalBlock

const Grid = styled(BaseGrid)`
  row-gap: 32px;

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 80px;
  }
`

const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  padding: 48px 0 32px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal10};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 120px 0 80px 0;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: row;
    row-gap: 40px;
    padding-bottom: 120px;
  }
`

const Directories = styled.div`
  display: none;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
`

const Tab = styled.div``

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px;
  gap: 16px;
`

const Section = styled.div`
  cursor: pointer;
  transition: color 300ms ease;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.colors.charcoal60};
  ${({ theme }) => theme.typography.h5.mobile}

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${({ theme }) => theme.typography.h5.desktop}
  }

  &:hover {
    color: ${({ theme }) => theme.colors.charcoal};
  }
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;

  select {
    width: 100%;
  }

  flex: 1;
`

const MobileDirectory = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`

const Markdown = styled(ReactMarkdown)`
  p {
    margin-bottom: 16px !important;
  }

  h3 {
    margin-bottom: 40px;
    font-weight: 600;
  }

  h4 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style: disc !important;
  }
`
