import { AnimatePresence, motion } from 'framer-motion'
import ManageCookiesModal, { CookieSettings } from 'library/modal/ManageCookiesModal/ManageCookiesModal'
import React, { ReactNode, useEffect, useState } from 'react'
import { SchemeType, theme } from 'theme'

import CookieBanner from './cookies/CookieBanner'
import FacebookPixel from 'layout/scripts/FacebookPixel'
import Footer from './components/Footer'
import GoogleTag from 'layout/scripts/GoogleTag'
import Header from './components/Header'
import Hotjar from 'layout/scripts/Hotjar'
import Hubspot from 'layout/scripts/Hubspot'
import Intercom from 'layout/scripts/Intercom'
import LinkedinInsightTag from 'layout/scripts/LinkedinInsightTag'
import Menu from './components/Menu'
import WhatConverts from 'layout/scripts/WhatConverts'
import styled from 'styled-components'
import useConfig from 'helpers/useConfig'
import { useGlobalContexts } from './GlobalContextsProvider'

type Props = {
  scheme?: SchemeType
  children: ReactNode
}

const Layout = ({ scheme = 'light', children }: Props) => {
  const { showCookieBanner, setShowCookieBanner, showManageCookiesModal, setShowManageCookiesModal } =
    useGlobalContexts()

  const [showMenu, setShowMenu] = useState(false)
  const [cookieSettings, setCookieSettings] = useState<CookieSettings | null>(null)

  const openMenu = () => setShowMenu(true)
  const closeMenu = () => setShowMenu(false)

  const config = useConfig()

  useEffect(() => {
    document.body.style.backgroundColor = scheme === 'light' ? theme.colors.white : theme.colors.navyDark

    return () => document.body.removeAttribute('style')
  }, [scheme])

  useEffect(() => {
    const $cookieSettings = localStorage.getItem('cookieSettings')

    if ($cookieSettings) {
      setCookieSettings(JSON.parse($cookieSettings))
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [setShowCookieBanner])

  const handleAcceptCookies = () => {
    handleSaveCookies({
      functional: true,
      analytics: true,
      marketing: true,
    })
    setShowCookieBanner(false)
  }

  const handleSaveCookies = (cookies: CookieSettings) => {
    localStorage.setItem('cookieSettings', JSON.stringify(cookies))
    setShowManageCookiesModal(false)
    setCookieSettings(cookies)
  }

  return (
    <>
      {cookieSettings && cookieSettings.analytics && <Hotjar siteId={config.hotjarSiteId} />}

      {cookieSettings && cookieSettings.analytics && (
        <GoogleTag
          analyticsMeasurementId={config.googleAnalyticsMeasurementId}
          analyticsTrackingId={config.googleAnalyticsTrackingId}
        />
      )}

      {cookieSettings && cookieSettings.analytics && cookieSettings.marketing && (
        <GoogleTag adsId={config.googleAdsId} />
      )}

      {cookieSettings && cookieSettings.analytics && cookieSettings.marketing && (
        <WhatConverts profileId={config.whatConvertsProfileId} />
      )}

      <Container>
        <header>
          <Header openMenu={openMenu} />
        </header>

        <AnimatePresence>
          <Content
            key='content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {children}
          </Content>

          <footer>
            <Footer scheme={scheme} />
          </footer>
        </AnimatePresence>
      </Container>

      <Menu show={showMenu} onClose={closeMenu} />

      {showCookieBanner && <CookieBanner onAccept={handleAcceptCookies} />}

      {!showCookieBanner && <Intercom appId={config.intercomAppId} />}

      {showManageCookiesModal && (
        <ManageCookiesModal
          cookieSettings={cookieSettings}
          onClose={() => setShowManageCookiesModal(false)}
          onSave={handleSaveCookies}
        />
      )}

      {cookieSettings && cookieSettings.marketing && <FacebookPixel pixelId={config.facebookPixelId} />}

      {cookieSettings && cookieSettings.marketing && <LinkedinInsightTag partnerId={config.linkedinPartnerId} />}

      {cookieSettings && cookieSettings.marketing && <Hubspot hubspotId={config.hubspotId} />}
    </>
  )
}

export default Layout

const Container = styled.div`
  min-width: 375px;
`

const Content = styled(motion.div)`
  padding-top: 64px;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-top: 82px;
  }
`
